/
<template>
  <div>
    <page-header :title="`Tạo mới người dùng`" :items="breadcrumbs" />
    <div v-if="!loading">
      <b-row>
        <b-col lg="8">
          <b-card>
            <b-form-group>
              <label>Họ tên <span class="red">*</span></label>
              <b-input v-model="form.Name" placeholder="Nhập họ tên" />
            </b-form-group>
            <b-form-group>
              <label>Số điện thoại/Email<span class="red">*</span></label>
              <b-input v-model="form.Id" placeholder="Nhập số điện thoại/ Email" />
            </b-form-group>
            <b-form-group>
              <label>Tỉnh/Thành <span class="red">*</span></label>
              <b-form-select :disabled="this.role_login != ADMIN" v-model="form.Province" :options="provinces">
                <template #first>
                  <b-form-select-option :value="null" disabled>Chọn Tỉnh/Thành</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label>Quận/Huyện <span class="red">*</span></label>
              <b-form-select :disabled="
                this.role_login != ADMIN &&
                this.role_login != PROVINCE_OFFICIAL
              " v-model="form.District" :options="districts">
                <template #first>
                  <b-form-select-option :value="null" disabled>Chọn Quận/Huyện</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label>Phường/Xã <span class="red">*</span></label>
              <b-form-select :disabled="
                this.role_login == WARD_OFFICIAL ||
                this.role_login == PROCESS_OFFICIAL
              " v-model="form.Ward" :options="wards">
                <template #first>
                  <b-form-select-option :value="null" disabled>Chọn Phường/Xã</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label>Địa chỉ <span class="font">(Số nhà, đường/thôn, xóm)</span><span class="red">*</span></label>
              <b-input v-model="form.Address" placeholder="Nhập địa chỉ" />
            </b-form-group>
            <b-form-group v-if="form.role_level != USER">
              <label>Cấp quyền <span class="red">*</span></label>
              <b-form-select v-model="form.Role_Level">
                <template #first>
                  <b-form-select-option :value="null" disabled>Chọn quyền</b-form-select-option>
                </template>
                <b-form-select-option v-if="role_login == ADMIN" :value="PROVINCE_OFFICIAL">
                  Cán bộ tỉnh
                </b-form-select-option>
                <b-form-select-option v-if="role_login == ADMIN || role_login == PROVINCE_OFFICIAL"
                  :value="DISTRICT_OFFICIAL">
                  Cán bộ huyện
                </b-form-select-option>
                <b-form-select-option v-if="role_login == ADMIN || role_login == DISTRICT_OFFICIAL"
                  :value="WARD_OFFICIAL">
                  Cán bộ xã
                </b-form-select-option>
                <b-form-select-option v-if="role_login == ADMIN || role_login == WARD_OFFICIAL"
                  :value="PROCESS_OFFICIAL">
                  Cán bộ xử lý
                </b-form-select-option>
                <b-form-select-option v-if="role_login == ADMIN || role_login == WARD_OFFICIAL" :value="USER">
                  Người dân
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="form.Role_Level != USER && form.Role_Level != '' && form.Role_Level != null">
              <label>Chức vụ <span class="red">*</span></label>
              <b-input v-model="form.Position" placeholder="Nhập chức vụ" />
            </b-form-group>
            <b-form-group>
              <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                {{ addErrorMessage }}
              </div>
              <b-button variant="success" class="mr-2" @click.prevent="create">
                Lưu
              </b-button>
              <b-button variant="danger" @click.prevent="toListView">
                Huỷ
              </b-button>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../../components/page-header";
import {
  ADMIN,
  PROVINCE_OFFICIAL,
  DISTRICT_OFFICIAL,
  WARD_OFFICIAL,
  PROCESS_OFFICIAL,
  USER,
} from "../../../role/checkTypeRole";
import { httpClient } from "../../../_utils/httpClient";
import Swal from "sweetalert2";
import { Base64 } from "js-base64";

export default {
  name: "create",
  components: {
    PageHeader,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          text: "Trang chủ",
          to: "/admin/home",
        },
        {
          text: "Người dùng",
          to: "/admin/users",
        },
        {
          text: "Thêm mới",
          to: "#",
          active: true,
        },
      ],
      checkProvince: 0,
      checkDistrict: 0,
      showAddErrorMessage: false,
      addErrorMessage: "",
      form: {
        Name: "",
        Id: "",
        Province: null,
        District: null,
        Ward: null,
        Address: "",
        Role_Level: null,
        Position: null,
        action: "Thêm mới",
      },
      provinces: [],
      districts: [],
      wards: [],
      loading: false,
      role_login: null,
      ADMIN,
      PROVINCE_OFFICIAL,
      DISTRICT_OFFICIAL,
      WARD_OFFICIAL,
      PROCESS_OFFICIAL,
      USER,
    };
  },
  watch: {
    "form.Province": {
      handler: function () {
        if (this.checkProvince > 0) {
          this.form.District = null;
        }
        this.checkProvince = 1;
        this.getDistricts(this.form.Province);
      },
      deep: true,
    },
    "form.District": {
      handler: function () {
        if (this.checkDistrict > 0) {
          this.form.Ward = null;
        }
        this.checkDistrict = 1;
        this.getWards(this.form.Province, this.form.District);
      },
      deep: true,
    },
  },
  methods: {
    getRoleLogin() {
      let userData = localStorage.getItem("token").split(".")[1];
      let user = JSON.parse(Base64.decode(userData));
      if (user.role.includes("admin")) {
        this.role_login = "admin";
      } else {
        this.role_login = user.role_level;
      }
      if (this.role_login == PROVINCE_OFFICIAL) {
        this.form.Province = user.province;
      } else if (this.role_login == DISTRICT_OFFICIAL) {
        this.form.Province = user.province;
        this.form.District = user.district;
      } else if (
        this.role_login == WARD_OFFICIAL ||
        this.role_login == PROCESS_OFFICIAL
      ) {
        this.form.Province = user.province;
        this.form.District = user.district;
        this.form.Ward = user.ward;
      } else {
        return;
      }
    },
    async toListView() {
      await this.$router.push({
        name: "admin.users.list",
      });
    },
    isNumeric(value) {
      return Number.isInteger(parseInt(value));
    },
    onlySpaces(str) {
      str = str.toString();
      return str.trim().length === 0;
    },
    validateEmail() {
      if (
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
          this.form.Id
        )
      ) {
        return true;
      } else if (this.isNumeric(this.form.Id) && this.form.Id.length === 10) {
        return true;
      } else {
        return false;
      }
    },
    capitalising(data) {
      var capitalized = [];
      data.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
    validator() {
      let err = "Số điện thoại hoặc Email không hợp lệ";
      let mappingErrorName = {
        Name: "Họ tên",
        Id: "Số điện thoại",
        Province: "Tỉnh/Thành",
        District: "Quận/Huyện",
        Ward: "Phường/Xã",
        Address: "Địa chỉ",
        Role_Level: "Cấp quyền",
        Position: "Chức vụ",
      };

      for (let key in this.form) {
        if (key == "Position" && this.form.Role_Level == 99) {
          this.form.Position = null;
          continue;
        }
        if (key == "Address") {
          if (this.form[key].trim() == "") {
            this.showAddErrorMessage = true;
            this.addErrorMessage = mappingErrorName[key] + ` không được trống.`;
            return false;
          }
        }
        if (this.form[key] == null || this.form[key].length < 1 || this.onlySpaces(this.form[key])) {
          this.showAddErrorMessage = true;
          this.addErrorMessage = mappingErrorName[key] + ` không được trống.`;
          return false;
        }
      }
      if (!this.validateEmail()) {
        this.showAddErrorMessage = true;
        this.addErrorMessage = err;
        return false;
      }
      return true;
    },

    async create() {
      await Swal.queue([
        {
          title: "Tạo mới người dùng này!",
          confirmButtonText: "Lưu",
          cancelButtonText: "Hủy bỏ",
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          showCancelButton: true,
          showconfirmButton: true,
          preConfirm: async () => {
            this.showAddErrorMessage = false;
            if (!this.validator()) return;

            this.form.Name = this.capitalising(this.form.Name);
            let payload = {
              ...this.form,
            };
            let response = await httpClient.post(`user/create`, payload);

            if (response.data.code === 0) {
              if (this.form.Role_Level !== USER) {
                Swal.insertQueueStep({
                  title: "Tạo mới thành công",
                }),
                  await this.$router.push({
                    name: "admin.users.list",
                  });
              } else {
                Swal.insertQueueStep({
                  title: "Tạo mới thành công",
                }),
                  await this.$router.push({
                    name: "admin.users.resident.list",
                  });
              }
            } else {
              this.showAddErrorMessage = true;
              this.addErrorMessage = response.data.message;
            }
            return true;
          },
        },
      ]);
    },
    async getProvince() {
      let response = await httpClient.get(`config/provinces`);
      this.provinces = response.data.data;
    },
    async getDistricts(province_name) {
      let response = await httpClient.get(`config/districts`, {
        params: {
          province_name,
        },
      });
      this.districts = response.data.data;
    },
    async getWards(province_name, district_name) {
      let response = await httpClient.get(`config/wards`, {
        params: {
          province_name,
          district_name,
        },
      });
      this.wards = response.data.data;
    },
  },
  async created() {
    await this.getProvince();
    this.getRoleLogin();
  },
};
</script>

<style>
.red {
  color: red;
}

.text-sm {
  font-weight: 600;
}

.vib-open {
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.vib-hideable {
  transition: opacity 0.5s ease;
}

.vib-hidden {
  opacity: 0;
}

/* elements styling */

.vib-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2000;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}

.vib-content {
  position: relative;
  margin: 0;
  display: block;
}

.vib-image {
  cursor: pointer;
  max-height: calc(100vh);
  cursor: pointer;
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.vib-thumbnail-wrapper {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  text-align: center;
  white-space: nowrap;
  position: fixed;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 0px;
}

.vib-thumbnail {
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.2);
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px 2px 0 2px;
  overflow: hidden;
  width: 50px;
}

.vib-thumbnail-active {
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px white;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px 2px 0 2px;
  overflow: hidden;
  width: 50px;
}

.vib-footer {
  box-sizing: border-box;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  line-height: 1.3;
  padding: 10px;
  justify-content: space-between;
  text-align: left;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.vib-footer-count {
  flex-shrink: 0;
  padding-left: 1em;
}

.vib-close {
  background: rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  outline: none;
  position: fixed;
  top: 0;
  right: 0;
  vertical-align: bottom;
  height: 65px;
  width: 60px;
  padding: 15px 12px 15px 20px;
  border-bottom-left-radius: 50%;
}

.vib-arrow {
  background: rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  user-select: none;
  height: 65px;
  margin-top: -60px;
  width: 60px;
}

.vib-arrow-left {
  left: 0;
  padding-right: 20px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.vib-arrow-right {
  right: 0;
  padding-left: 20px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

@media (min-width: 500px) {
  .vib-thumbnail-arrow {
    width: 40px;
  }
}

@media (min-width: 768px) {
  .vib-arrow {
    width: 60px;
  }
}

/* image transitions */

.vib-image-transition-enter-active,
.vib-image-transition-leave-active {
  transition: opacity 0.2s ease;
}

.vib-image-transition-enter,
.vib-image-transition-leave-to {
  opacity: 0;
}

.vib-image-no-transition-enter-active,
.vib-image-no-transition-leave-active {
  transition: none;
}

.vib-image-no-transition-enter,
.vib-image-no-transition-leave-to {
  opacity: 0;
}

/* container transitions */

.vib-container-transition-enter-active,
.vib-container-transition-leave-active {
  transition: opacity 0.3s ease;
}

.vib-container-transition-enter,
.vib-container-transition-leave-to {
  opacity: 0;
}

.video-background {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  width: calc(90vw);
  height: calc(90vh);
}

.video-background iframe {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50.25vw;
  max-height: 100vh;
  max-width: 182.78vh;
}
</style>
<style lang="scss" scoped>
.action-column {
  width: 120px;
  text-align: center;
}

.font {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
}
</style>
